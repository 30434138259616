import { Link } from "@chakra-ui/react";
import { SocialIcon } from "react-social-icons";

type Props = {
  href: string;
  network: string;
  size: number;
  target?: string;
};

const SocialIconLink = (props: Props, { ...rest }) => {
  return (
    <Link
      href={props.href}
      target={props.target ? props.target : "_blank"}
      {...rest}
      _hover={{
        transition: "all .2s ease-in-out",
        translate: "0 -.2rem",
      }}
    >
      <SocialIcon
        network={props.network}
        style={{ height: props.size, width: props.size }}
        fgColor={"black"}
        bgColor={"white"}
        href={props.href}
      />
    </Link>
  );
};

export default SocialIconLink;
