import { Heading, Box, Image, Link, Text } from "@chakra-ui/react";
import { FunctionComponent, ReactElement } from "react";

interface ProjectImageProps {
  link?: string;
  target?: string;
  imgSrc: string;
  imgAlt?: string;
  grayscale?: number;
}

interface ProjectCardProps {
  link?: string;
  target?: string;
  imgSrc: string;
  imgAlt?: string;
  projectName: string;
  projectDescription: ReactElement;
  grayscale?: number;
}

const ProjectImage: FunctionComponent<ProjectImageProps> = (props) => {

  const img = <Image
    src={props.imgSrc}
    alt={props.imgAlt}
    objectFit={"cover"}
    w={"100%"}
    transition={"0.3s ease-in-out"}
    filter={`grayscale(${props.grayscale ? props.grayscale : 0}) contrast(1.05)`}
    borderRadius={"0.3rem"}
    _hover={{
      translate: "0 -1rem",
      filter: `grayscale(0) contrast(1.0) brightness(1.3)`,
      boxShadow: "0 0 2rem rgba(0, 0, 0, 1)",
    }}
    draggable={false}
  />

  if (props.link) {
    return <Link href={props.link} target={props.target} >
      {img}
    </Link>
  }

  return img;
}

const ProjectCard: FunctionComponent<ProjectCardProps> = (props) => {
  return (
    <Box w={{ base: "80vw", lg: "40%" }}
      textAlign={"left"}>
      <ProjectImage {...props} />
      <Heading as="h3" size="md" w={"100%"} mt={"2rem"}>
        {props.projectName}
      </Heading>
      <Text w={"100%"} mt="1rem" color={"gray.900"} height={"20vh"} overflow={"hidden"}>
        {props.projectDescription}
      </Text>
    </Box>
  );
}

export default ProjectCard;