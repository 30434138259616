import { Box, Flex, Heading, Link, Show, Stack, Text, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import ProjectCard from "../components/ProjectCard";
import doFeatureImg from "../assets/img/sg_website_feature_do2_1x.webp";
import sdFeatureImg from "../assets/img/sg_website_feature_sd_1x.webp";
import bgProjectsImg from "../assets/img/bgProjects.webp";
import { DISCORD_LINK } from "../App";

interface ProjectsSectionProps {

}

const ProjectsSection: FunctionComponent<ProjectsSectionProps> = () => {
  return <Box
    w={"100%"}
    bgImg={bgProjectsImg}
    bgSize={"cover"}
    bgPos={"top"}
    bgRepeat={"no-repeat"}
  >
    <Flex
      w={"100%"}
      p={{ base: "5rem 0", "lg": "5vw 0" }}
      pb={{ base: "3rem", lg: "5vw" }}
      justifyContent={"center"}
    >
      <VStack w={"100%"}>
        <Heading
          as={"h3"}
          size={"lg"}
          pt={{ base: "0", lg: "1vw" }}
          pb={{ base: "3rem", lg: "5vw" }}
          m={"0 auto"}
          id="projects"
          zIndex={1}
        >
          What we&apos;re working on
        </Heading>
        <Stack
          w={"min(80%, 1000px)"}
          justifyContent={"center"}
          alignItems={{ base: "center", md: "flex-end" }}
          spacing={"3rem"}
          direction={{ base: "column-reverse", md: "row" }}
          zIndex={1}
        >
          <ProjectCard
            imgSrc={sdFeatureImg}
            imgAlt="Scientist's Doom"
            projectName={"Scientist's Doom"}
            projectDescription={
              <>
                The project where it all started ... Scientist&apos;s DOOM is our very first game developed during our studies at CTU, Prague
                and later featured at <Link href="https://19.game-access.com/conference/indie-showcase/scientists-doom/" target="_blank" aria-label="Read more about Scientist's DOOM at Game Access 2019 Indie Showcase." bgGradient='linear(to-l, #AA0454, #891C62)'
                  bgClip='text'>Game Access 2019</Link>.
              </>
            }
            grayscale={0.8} />
          <ProjectCard
            link={DISCORD_LINK}
            target="_blank"
            imgSrc={doFeatureImg}
            imgAlt="Dark Omen"
            projectName={"Dark Omen"}
            projectDescription={
              <>
                Topdown action RPG meets roguelite in Low-Poly. Powered by the Unity engine,
                this is our latest project currently in development. <Text as="p" pt={"0.5rem"} fontSize={"lg"} fontWeight={"700"}>Make sure to <Link href={DISCORD_LINK} bgGradient='linear(to-l, #5865F2, #959DFA)'
                  bgClip='text'>join our Discord</Link> for closed alpha access!</Text>
              </>
            } />
          {/* <Show above="lg">
            <Box w={{ base: "80%", md: "50%", lg: "33%" }} />
          </Show> */}
        </Stack>
      </VStack>
    </Flex>
  </Box>;
}

export default ProjectsSection;